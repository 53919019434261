export const candidatFCTHeaders = [
  {
    text: "TAC",
    value: "trigrammeTAC",
    sortable: false,
    align: 'center',
  },
  {
    text: "Nom",
    value: "nom",
    sortable: false,
    align: 'center',
  },
  {
    text: "Prenom",
    value: "prenom",
    sortable: false,
    align: 'center',
  },
  {
    text: "Date de disponibilité",
    value: "disponibilite",
    sortable: false,
    align: 'center',
  },
  // {
  //   text: "ASAP",
  //   value: "ASAP",
  //   sortable: false,
  //   align: 'center',
  //   width: '2%'
  // },
  {
    text: "Emploi",
    value: "profilFCT",
    sortable: false,
    align: 'center',
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Statut",
    value: "statut",
    sortable: false,
    align: 'center',
  },
  {
    text: "Prequa. tel",
    value: "prequa_tel",
    sortable: false,
    align: 'center',
  },
  {
    text: "Entité",
    value: "label_entite",
    sortable: false,
    align: 'center',
  },
  {
    text: "Pôle",
    value: "pole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Chasse",
    value: "source_chasse",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "Type de contrat",
    value: "label_contrat",
    sortable: false,
    align: 'center',
  },
  {
    text: "Cooptation",
    value: "cooptation",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "Remontée LinkedIn",
    value: "is_linkedin",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    align: 'center',
  }
];
