export const candidatsFCTFilters = [
  {
    text: "TAC",
    value: "trigrammeTAC", 
    selected: []
  },
  {
    text: "Disponibilité",
    value: "disponibilite",
    selected: []
  },
  {
    text: "Profil",
    value: "profilFCT",
    selected: []
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    selected: []
  },
  {
    text: "Statut",
    value: "statut",
    selected: ["OK"]
  },
  {
    text: "Préqua. tel",
    value: "prequa_tel",
    selected: []
  },
  {
    text: "Entité",
    value: "label_entite",
    selected: []
  },
  {
    text: "Pôle",
    value: "pole",
    selected: []
  },
  {
    text: "Type de contrat",
    value: "label_contrat",
    selected: []
  },
  {
    text: "Source chasse",
    value: "source_chasse",
    selected: []
  },
  {
    text: "Cooptation",
    value: "cooptation",
    selected: []
  },
  {
    text: "Linkedin",
    value: "is_linkedin",
    selected: []
  }
];